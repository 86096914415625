@font-face {
  font-family: "Trattatello";
  src: local("Trattatello"),
   url("./fonts/Trattatello.ttf") format("truetype");
 }

body {
  margin: 0;
  font-family: "Trattatello", "Comic Sans MS", "Comic Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Trattatello", "Comic Sans MS", "Comic Sans";
}
