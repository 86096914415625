.AnimateItemButton {
    border: 3px dotted #1c87c9;
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  @keyframes beat {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    100% {
      -webkit-transform: scale(0.1, 0.1);
              transform: scale(0.1, 0.1);
    }
  }
  .AnimatePush {
    background: transparent;
    border: 0px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  .AnimatePush:focus {
    -webkit-animation: beat 0.2s ease-in-out backwards;
            animation: beat 0.2s ease-in-out backwards;
  }
  .AnimatePush:active {
    -webkit-animation: none;
            animation: none;
  }