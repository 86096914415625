.Game {
    height: calc(100% - 20px);
    width: calc(50% - 20px);
    left:0;
    top:0;
    position: absolute;
  }

  .water-fadein {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
}
.water-fadeout{
    display: flex;
    align-items: center;
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}
    
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}