.Question {
    height: calc(100% - 20px);
    width: calc(50% - 40px);
    left: calc(50% + 10px);
    position: absolute;
    top:0;
  }

  .container {
    /*display: inline-block;*/
    position: absolute;
    font-family: "Trattatello";
  }
  
  .typing {
    font-size: 1.9vw;
    line-height: 2;
    color:#0000;
    background:
      linear-gradient(-90deg,#0000 5px,#0000 0) 10px 5px,
      linear-gradient(#000000 0 0) 0 0;
    background-size:calc(var(--n)*1ch) 200%;
    -webkit-background-clip:padding-box,text;
    background-clip:padding-box,text;
    background-repeat:no-repeat;
    animation: 
      b .1s infinite steps(1),   
      t calc(var(--n)*.04s) steps(var(--n)) forwards;
  }
  @keyframes t{
    from {background-size:0 200%}
  }
  @keyframes b{
    50% {background-position:0 -100%,0 0}
  }

  .typed {
    font-size: 1.9vw;
    line-height: 2;
  }

  .question {
    font-size: 1.9vw;
    line-height: 2;
    padding: 0;
    margin: 0;
  }

  .level {
    font-size: 1.5vw;
    padding: 0;
    margin: 0;
  }

  .score {
    font-size: 2.9vw;
    padding: 0;
    margin: 0;
  }


  .pop-outin {
    animation: 2s anim-popoutin ease;
    font-size: 2.35vw;
    /*line-height: 2;*/
    padding: 0;
    margin: 0;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  
  @keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0);
      opacity: 0;
    }
    25% {
      color: black;
      transform: scale(1.1);
      opacity: 1;
    }
    50% {
      color: black;
      transform: scale(0.8);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media screen and (max-width: 540px) {
    .typing {
      line-height: 1;
      font-size: 1.6vw;
    }
    .typed {
      line-height: 1;
      font-size: 1.6vw;
    }
    .question {
      line-height: 1;
      font-size: 1.6vw;
    }
  }